import 'maplibre-gl/dist/maplibre-gl.css';
import './switcher/switcher.css';
import maplibregl from 'maplibre-gl';
import React, {
  useRef, useLayoutEffect, useEffect, useState,
} from 'react';
import { SwitcherControl } from './switcher/switcher';
import deviceCategories from '../common/deviceCategories';
import { prepareIcon, loadImage, loadCategoryImages } from './mapUtil';
import {
  styleCarto, styleLocationIq, styleMapbox, styleMapTiler, styleOsm,
} from './mapStyles';
import { useAttributePreference } from '../common/preferences';
import palette from '../theme/palette';
import { useTranslation } from '../LocalizationProvider';

const element = document.createElement('div');
element.style.width = '100%';
element.style.height = '100%';

export const map = new maplibregl.Map({
  container: element,
});

let ready = false;
const readyListeners = new Set();

const addReadyListener = (listener) => {
  readyListeners.add(listener);
  listener(ready);
};

const removeReadyListener = (listener) => {
  readyListeners.delete(listener);
};

const updateReadyValue = (value) => {
  ready = value;
  readyListeners.forEach((listener) => listener(value));
};

let mapIdleFlag = true;

const initMap = async () => {
  if (ready) return;
  console.log(`initMap started::::::::::::::::::::::::::`)
  if (!map.hasImage('background')) {
    const background = await loadImage('/images/background.svg');

    if (!map.hasImage('background')) {
      map.addImage('background', await prepareIcon(background), {
        pixelRatio: window.devicePixelRatio,
      });
    }

    const categoryImages = await loadCategoryImages()
    const colorList = ['Black', 'White', 'Brown', 'Gray', 'Red', 'Blue', 'Green', 'Silver', 'Gold', 'Yellow']
    const accList = [0, 1]
    const accIcon = await loadImage(`/images/accIcon-1.jpg`)
    for (let k in deviceCategories) {
      const category = deviceCategories[k]
      const icon = categoryImages[category]
      for (let i in colorList) {
        const color = colorList[i]
        for (let a in accList) {
          const acc = accList[a]
          if (!map.hasImage(`${category}-${color}-${acc}`)) {
            map.addImage(`${category}-${color}-${acc}`, await prepareIcon(background, icon, palette.common[color], acc === 1 ? accIcon : null), {
              pixelRatio: window.devicePixelRatio,
            });
          }
        }
      }
    }
  }
  console.log(`initMap all icons loaded::::::::::::::::::::::::::`)

  updateReadyValue(true);

  // map.once('idle', (e) => {
  //   // do things the first time the map idles
  //   //console.log("Map idle once:::::::::::::::::::::::::::::")
  // });
  map.on('idle', () => {
    //console.log("Map idle::::::::::::::::::::::::::::::::::")
    //alert("Map idle::::::::::::::::::::::::::::::::::")
    //map.resize();
    mapIdleFlag = !mapIdleFlag;
  });

  console.log(`initMap end::::::::::::::::::::::::::`)
};

map.addControl(new maplibregl.NavigationControl({
  showCompass: false,
}));

const switcher = new SwitcherControl(
  () => updateReadyValue(false),
  () => {
    const waiting = () => {
      if (!map.loaded()) {
        setTimeout(waiting, 100);
      } else {
        initMap();
      }
    };
    waiting();
  },
);

map.addControl(switcher);

const Map = ({ children }) => {
  const containerEl = useRef(null);
  const t = useTranslation();

  const [mapReady, setMapReady] = useState(false);

  const mapboxAccessToken = useAttributePreference('mapboxAccessToken');
  const mapTilerKey = useAttributePreference('mapTilerKey');
  const locationIqKey = useAttributePreference('locationIqKey', 'pk.0f147952a41c555a5b70614039fd148b');

  useEffect(() => {
    maplibregl.accessToken = mapboxAccessToken;
  }, [mapboxAccessToken]);

  useEffect(() => {
    switcher.updateStyles([
      { id: 'locationIqStreets', title: t('mapLocationIqStreets'), uri: styleLocationIq('streets', locationIqKey) },
      { id: 'locationIqEarth', title: t('mapLocationIqEarth'), uri: styleLocationIq('earth', locationIqKey) },
      { id: 'locationIqHybrid', title: t('mapLocationIqHybrid'), uri: styleLocationIq('hybrid', locationIqKey) },
      { id: 'osm', title: t('mapOsm'), uri: styleOsm() },
      { id: 'carto', title: t('mapCarto'), uri: styleCarto() },
      { id: 'mapboxStreets', title: t('mapMapboxStreets'), uri: styleMapbox('streets-v11') },
      { id: 'mapboxOutdoors', title: t('mapMapboxOutdoors'), uri: styleMapbox('outdoors-v11') },
      { id: 'mapboxSatellite', title: t('mapMapboxSatellite'), uri: styleMapbox('satellite-v9') },
      { id: 'mapTilerBasic', title: t('mapMapTilerBasic'), uri: styleMapTiler('basic', mapTilerKey) },
      { id: 'mapTilerHybrid', title: t('mapMapTilerHybrid'), uri: styleMapTiler('hybrid', mapTilerKey) },
    ], 'osm');
  }, [mapTilerKey]);

  useEffect(() => {
    //console.log("mapIdleFlag:::::", mapIdleFlag)
    const listener = (ready) => setMapReady(ready);
    addReadyListener(listener);
    return () => {
      removeReadyListener(listener);
    };
  }, []);

  useLayoutEffect(() => {
    const currentEl = containerEl.current;
    currentEl.appendChild(element);
    map.resize();
    return () => {
      currentEl.removeChild(element);
    };
  }, [containerEl]);

  return (
    <div style={{ width: '100%', height: '100%' }} ref={containerEl}>
      {mapReady && children}
    </div>
  );
};

export default Map;
