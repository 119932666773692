import React, { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';

import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import {
  Grid, useMediaQuery, makeStyles, InputLabel, Select, MenuItem, Button, TextField, Typography
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sessionActions } from '../../store';
import { useLocalization, useTranslation } from '../../LocalizationProvider';
import StartPage from '../../StartPage';
import usePersistedState from '../../common/usePersistedState';
import { api_call, console_log, getToken, get_utc_timestamp_ms, setToken } from '../../helpers/untils';
import { GPS_ID_PLACEHOLDER, ICCID_PLACEHOLDER, IMAGE_CACHE_VERSION, RECAPTCHA_ENABLED, RECAPTCHA_KEY } from '../../config/constant';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { getSettingPersist, setSettingPersist, sleep } from '../../helpers/misc';
import { Alert } from '@mui/material';
import CaptchaBox from '../CaptchaBox';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  imgSmall: {
    width: '50px',
    height: '40px',
    marginLeft: '5px',
    marginTop: '5px',
    borderRadius: '4px',
    cursor: 'pointer',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  customSnakebar: {
    position: 'absolute',
    top: '140px'
  },
  mobileInstallerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center'
  }
}));

const InstallerLoginFormOld = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const t = useTranslation();

  const { languages, language, setLanguage } = useLocalization();
  const languageList = Object.entries(languages).map((values) => ({ code: values[0], name: values[1].name }));

  const [failed, setFailed] = useState(false);

  const formDefaultData = {
    iccid: "", // "000000000"
    uniqueId: "", // "MT09G00000"
    phone_email: "",
    mobile_installer: "0"
  }
  const [formData, setFormData] = useState(formDefaultData);
  const [isOpen, setIsOpen] = useState('');
  const [currentImage, setCurrentImage] = useState('');

  const [submitting, setSubmitting] = useState(false);

  const iccidImage = `/images/iccid/large1.jpg?v=${IMAGE_CACHE_VERSION}`
  const gpsidImage = `/images/gpsid/large1.jpg?v=${IMAGE_CACHE_VERSION}`

  ///////////// start recaptcha part //////////////////////////////////////////////////////////////////////////
  const captchaKey = 'captcha_installer_login'
  const [recaptcha, setRecaptcha] = useState(RECAPTCHA_ENABLED === "false" ? "xxxxxxxxxxxxxxxxx" : "");
  const [captchaTimestamp, setCaptchaTimestamp] = useState(get_utc_timestamp_ms());
  const updateCaptchaSettingEnabled = (value) => {
    const oldValue = getSettingPersist(captchaKey)
    if (true || oldValue !== value) { // refresh every attempt
      setSettingPersist(captchaKey, value)
      setCaptchaTimestamp(get_utc_timestamp_ms())
    }
  }
  ///////////// end recaptcha part //////////////////////////////////////////////////////////////////////////

  const handleSubmit = async (event) => {
    event.preventDefault();
    const rand = () => Math.floor((1 + Math.random()) * 0x100000000).toString(16).substring(1);
    const session = "installer-" + rand();
    var data = { ...formData, session: session };

    setSubmitting(true)
    const response = await api_call('/api/check-installer-device', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const sessionData = await response.json();
      console_log("sessionData:::::::", sessionData);
      if (sessionData['error']) {
        setToast(sessionData['error'])
        setSnackbarOpen(true);
        setSubmitting(false)
        return false
      }
    }
    await processSubmit(data)
  }

  const processSubmit = async (data) => {
    setToast("Please wait, Process can take up to 20 minutes.")
    setSnackbarOpen(true)
    setSubmitting(true)

    const response = await api_call('/api/installer-auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const sessionData = await response.json();
      console_log("sessionData:::::::", sessionData);
      if (sessionData['error']) {
        setToast(sessionData['error'])
        setSnackbarOpen(true);
        setSubmitting(false)
        updateCaptchaSettingEnabled(true)
      } else {
        if (sessionData['status'] === 'waiting') {
          await scanCheckGpsIccidMatched({ ...data })
          if (sessionData['message']) {
            setToast(sessionData['message'])
            setSnackbarOpen(true);
          }
        } else {
          redirectToMainPage(sessionData)
          updateCaptchaSettingEnabled(false)
          return true
        }
      }
    } else {
      setFailed(true);
      //setFormData(formDefaultData);
      setToast("Invalid combination")
      setSnackbarOpen(true)
      setSubmitting(false)
      updateCaptchaSettingEnabled(true)
    }

    setTimeout(() => {
      setSnackbarOpen(false)
    }, 3000)
  }

  const scanCheckGpsIccidMatched = async (data) => { // check every 30s for 10 minutes
    let i = 0;
    let cnt = 18; //18
    for (i = 0; i < cnt; i++) {
      let is_final = 0
      if (i === cnt - 1) {
        is_final = 1
      }
      const rslt = await checkGpsIccidMatched(data, is_final);
      if (rslt) { // if true, don't need to scan again
        return false
      }
      await sleep(30 * 1000)  //30 * 1000
    }
  }

  const checkGpsIccidMatched = async (data, is_final) => { // check every 30s for 10 minutes
    const response = await api_call('/api/check-gpsid-iccid-matched', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...data, is_final: is_final }),
    });
    if (response.ok) {
      const sessionData = await response.json();
      console_log("sessionData:::::::", sessionData);
      if (sessionData['error']) {
        if (is_final === 1) {
          setToast(sessionData['error'])
          setSnackbarOpen(true);
          setSubmitting(false)
          updateCaptchaSettingEnabled(true)
          return true
        } else {
          return false // scan again
        }
      } else {
        redirectToMainPage(sessionData)
        updateCaptchaSettingEnabled(false)
        return true
      }
    } else {
      setToast("Invalid combination")
      setSnackbarOpen(true)
      setSubmitting(false)
      return true
    }
  }

  const sendInstallerAuthEmail = async (sessionData) => {
    const response = await api_call('/api/send-installer-auth-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...sessionData }),
    });
    // if (response.ok) {
    //   const sessionData = await response.json();
    // }
  }

  const redirectToMainPage = async (sessionData) => {
    console_log("sessionData::::", sessionData)
    //return false;
    await sendInstallerAuthEmail(sessionData)

    setToken(sessionData['token'])
    history.push('/installer-main') //window.location.href = '/installer-main';
  }

  const handleSpecialKey = (e) => {
    if (e.keyCode === 13 && formData['iccid'] && formData['uniqueId'] && recaptcha) {
      handleSubmit(e);
    }
  };
  const onChange = (value) => {
    console.log(value);
    setRecaptcha(value);
  }

  const applyLogout = async () => {
    const m_token = getToken();
    if (m_token) {
      const url = `/api/installer-auth/${m_token}`
      const response = await api_call(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      setToken("")
    }
  }

  useEffect(() => {
    applyLogout()
  }, []);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toast, setToast] = useState("");
  return (
    <>
      <StartPage>
        <div className='custom-snakebar'>
          <Snackbar
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={10 * 60 * 1000}
            message={toast}
          >
          </Snackbar>
        </div>

        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={2}>
            {useMediaQuery(theme.breakpoints.down('md'))
              && (
                <Grid item className={classes.logoContainer}>
                  <Link to="/">
                    <img height="85" width="240" src="/logo.png" alt="Mobile Logo Login" />
                  </Link>
                </Grid>
              )}

            <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
              <TextField
                required
                fullWidth
                error={failed}
                label={`GPS ID`}
                name="uniqueId"
                value={formData['uniqueId']}
                type="text"
                onChange={(e) => setFormData({ ...formData, uniqueId: e.target.value })}
                onKeyUp={handleSpecialKey}
                variant="filled"
                inputProps={{ style: { textTransform: "uppercase" }, maxLength: "10" }}
                placeholder={GPS_ID_PLACEHOLDER}
                disabled={submitting}
              />
              <img className={classes.imgSmall}
                src={`/images/gpsid/small1.jpg?v=${IMAGE_CACHE_VERSION}`}
                alt="How to get GPSID"
                title="How to get GPSID"
                onClick={() => {
                  setCurrentImage(gpsidImage)
                  setIsOpen(true)
                }}
              />
            </Grid>
            <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
              <TextField
                required
                fullWidth
                error={failed}
                label={`ICCID`}
                name="iccid"
                value={formData['iccid']}
                autoComplete="iccid"
                autoFocus={true}
                onChange={(e) => setFormData({ ...formData, iccid: e.target.value.substring(0, 9) })}
                onKeyUp={handleSpecialKey}
                //helperText={failed && 'The combination you entered is not found'}
                variant="filled"
                inputProps={{ style: { textTransform: "none" }, type: "number", min: 0 }}
                placeholder={ICCID_PLACEHOLDER}
                disabled={submitting}
              />
              <img className={classes.imgSmall}
                src={`/images/iccid/small1.jpg?v=${IMAGE_CACHE_VERSION}`}
                alt="How to get ICCID"
                title="How to get ICCID"
                onClick={() => {
                  setCurrentImage(iccidImage)
                  setIsOpen(true)
                }}
              />
            </Grid>
            <Grid item>

            </Grid>
            <Grid item>
              <TextField
                required
                fullWidth
                error={failed}
                label={`Installer Email`}
                name="phone_email"
                value={formData['phone_email']}
                type="text"
                onChange={(e) => setFormData({ ...formData, phone_email: e.target.value })}
                onKeyUp={handleSpecialKey}
                variant="filled"
                disabled={submitting}
                inputProps={{
                  type: 'email'
                }}
              />
            </Grid>

            {
              (RECAPTCHA_ENABLED === "true") && (
                <Grid item>
                  <div className={`recaptcha-wrpper`}>
                    <CaptchaBox
                      recaptcha={recaptcha}
                      setRecaptcha={setRecaptcha}
                      captchaKey={captchaKey}
                      captchaTimestamp={captchaTimestamp}
                    />
                  </div>
                </Grid>
              )
            }

            <Grid item>
              <Button
                type={`submit`}
                variant="contained"
                color="secondary"
                disabled={!formData['iccid'] || !formData['uniqueId'] || !formData['phone_email'] || !recaptcha || submitting}
                fullWidth
              >
                {`${(submitting) ? 'Processing' : 'Login'}`}
              </Button>
            </Grid>
          </Grid>
        </form>

        {isOpen && (
          <Lightbox
            mainSrc={currentImage}
            onCloseRequest={() => setIsOpen(false)}
          />
        )}

      </StartPage>
    </>

  );
};

export default InstallerLoginFormOld;
