import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  makeStyles, Button,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  formatDate
} from '../common/formatter';
import { api_call, is_null, removeItemFromArray } from '../helpers/untils';
import { DEVICE_TYPE } from '../config/constant';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '300px',
  },
  ...theme.palette.colors,
  listItemContainer: {
    // maxWidth: '280px',
  },
  creditValue: {
    cursor: 'pointer',
    padding: '5px',
    backgroundColor: 'rgba(76, 175, 80, 0.12)',
    border: '2px solid #4CAF50'
  },
  modalBottomButton: {
    minWidth: '40px'
  },
  modalBottomControlButton: {
    width: '100%',
    flex: 1,
    background: 'rgba(76, 175, 80, 0.01)',
    border: '2px solid rgba(76, 175, 80, 1)',
  }
}));

const CommandSendDialog = (props) => {
  const { onClose, selectedValue, open, commandsDetail, history, coHostDeviceDetail, device, userDeviceItem } = props;
  const user = useSelector((state) => state.session.user);

  const [commands, setCommands] = useState([]);
  const initCommandList = () => {
    let commandList = [];
    if (user.id) {
      commandList = ['Unlock', 'Lock', 'Horn', 'Unkill', 'Kill', 'Overspeed', 'Geofence'];
      if (user.administrator) {
        commandList = [...commandList, 'Reboot']
      }
    } else {
      commandList = ['Unlock', 'Lock', 'Horn'];
    }
    if (coHostDeviceDetail) {
      if (!coHostDeviceDetail.kill_switch) {
        commandList = removeItemFromArray(commandList, 'Unkill')
        commandList = removeItemFromArray(commandList, 'Kill')
      }
    }
    if (device.deviceType === DEVICE_TYPE.SMARTCAR || device.deviceType === DEVICE_TYPE.TESLA) {
      commandList = removeItemFromArray(commandList, 'Unkill')
      commandList = removeItemFromArray(commandList, 'Kill')
    }
    setCommands(commandList)
  }

  useEffect(() => {
    initCommandList()
  }, [open, coHostDeviceDetail])

  const handleClose = () => {
    onClose(selectedValue);
  };

  const [title, setTitle] = useState("");
  const defaultCount = 80
  const [count, setCount] = useState(defaultCount);

  useEffect(() => {
    if (commandsDetail) {
      if (!is_null(commandsDetail?.Overspeed?.count)) {
        setCount(commandsDetail?.Overspeed?.count)
      }
    }
  }, [commandsDetail])

  const handleListItemClick = async (value) => {
    // onClose(value);
    if (value === "Geofence") {
      let url = `/geofences/${props.deviceId}`;
      history.push(url);
      onClose(selectedValue);
      return false
    }
    if (value === "Overspeed" || value === "Kill" || value === "Unkill") {
      // count = prompt(value, "input value");
      setTitle(value);
      setOpenChild(true);
      return;
    }
    const response = await api_call(`/api/command?command=${value}&deviceId=${props.deviceId}&userId=${user.id}`);
    if (response.ok) {
      console.log("Sent Command.");
    }
    onClose(selectedValue);
  };

  const [openChild, setOpenChild] = React.useState(false);

  const handleChildClose = () => {
    setOpenChild(false);
  };
  const handleChildSend = async () => {
    // var command = title;
    // if(title === "Kill/Unkill") command = "Kill";
    let url = `/api/command?command=${title}&deviceId=${props.deviceId}&userId=${user.id}`;
    if (title === "Overspeed" || title === "Geofence") url = url + `&count=${count}`;
    const response = await api_call(url);
    if (response.ok) {
      console.log("Sent Command.");
    }
    setOpenChild(false);
  };

  const getDetail = (command) => {
    // var tempCommand = command;
    // if(command === "Kill/Unkill") tempCommand = "Kill";
    for (var key in commandsDetail) {
      if (key === command) {
        if (key === "Overspeed") return commandsDetail[key].count + "mph";
        else if (key === "Geofence") return "";
        return formatDate(commandsDetail[key].createdAt);
      }
    }
    return false;
  }
  const getStatus = (command) => {
    // if(command === "Kill/Unkill") command = "Kill";
    return commandsDetail[command].status;
  }

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Select Command</DialogTitle>
        <List sx={{ pt: 0 }}>
          {commands.map((command) => (
            <ListItem button onClick={() => handleListItemClick(command)} key={command}>
              {/* <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar> */}
              <ListItemText primary={command} secondary={getDetail(command)} />
              {getDetail(command) &&
                (
                  getStatus(command) === 'success' ?
                    <DoneAllIcon color="secondary" /> :
                    <CheckIcon color="disabled" />
                )
              }
            </ListItem>
          ))}
        </List>
      </Dialog>

      <Dialog open={openChild} onClose={handleChildClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {!(title === "Kill" || title === "Unkill") ? "Please enter the value here..." : "Are you sure?"}
          </DialogContentText>
          {!(title === "Kill" || title === "Unkill") && <TextField
            autoFocus
            // margin="dense"
            id="value"
            label={title}
            // type="number"
            fullWidth
            variant="standard"
            value={count}
            onChange={(e) => { setCount(e.target.value) }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{(title === "Overspeed") ? "mph" : "Miles Radius"}</InputAdornment>,
            }}
          />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChildClose}>Cancel</Button>
          <Button onClick={handleChildSend}>Send</Button>
        </DialogActions>
      </Dialog>

    </>
  );
}

export default CommandSendDialog;
