import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions } from '../store';

import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, Typography, Checkbox, Box, Link, Modal, TextField, Button, Snackbar, Accordion, AccordionSummary, AccordionDetails, Grid
} from '@material-ui/core';

import Avatar from '@material-ui/core/Avatar';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import { useParams } from 'react-router-dom';
import Pagination from '../components/pagination'

import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../LocalizationProvider';

import SelectField from '../form/SelectField';

import moment from 'moment';
import { api_call, console_log, empty, getDeviceImageUri, get_utc_timestamp } from '../helpers/untils';
import PaypalPaymentModal from './PaypalPaymentModal';
import { MIN_CREDIT_AMOUNT, PAYPAL_PLAN_NAME, PAYPAL_PLAN_PRICE, PAYPAL_PRODUCT_ID } from '../config/constant';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '470px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '30%',
    },
  },
  avatar: {
    width: '100%',
    maxWidth: '250px',
    height: 'auto',
    cursor: 'pointer',
  },

}));


const DeviceImageUploadModal = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const user = useSelector((state) => state.session.user);
  const { open, setOpen, item, setItem } = props;
  const [updateTimestamp, setUpdateTimestamp] = useState(0)

  useEffect(() => {
    if(item.image) {
      const uri = getDeviceImageUri(item)
      console_log("uri:::", uri)
      setImage(uri)
    }
  }, [open, item]);

  const handleModalClose = () => {
    setOpen(false)
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const fileRef = useRef();

  const defaultAvatar = `images/icon/${item.category || 'Default'}.svg`
  const [uploadFile, setUploadFile] = useState(null);
  const [image, setImage] = useState("")
  const onFileInputChanged = (e) => {
    setUploadFile(e.target.files[0]);
    let files = e.target.files;
    console_log(files);
    if (!empty(files)) {
      setImage(URL.createObjectURL(files[0]))
    } else {
      setImage("")
    }
  }

  const [apiLoading, setApiLoading] = useState(false)

  const onClickFileBtn = () => {
    fileRef.current.click();
  }

  const onClickSaveBtn = async () => {
    if(uploadFile === null) {
      console.log("uploadFile", uploadFile)
      if(item.image === ""){
        setToastMessage(`Please choose a image file to upload`);
        setSnackbarOpen(true);
      }else{
        handleModalClose()
      }
      return false;
    }
    const url = `/api/devices/uploadImage`;
    var formData = new FormData();
    formData.append("user_id", user.id);
    formData.append("device_id", item.id);
    formData.append("upload_file", uploadFile);

    setApiLoading(true)
    let response = await api_call(url, {
      method: 'POST',     
      body: formData,
    });

    setApiLoading(false)
    if (response.ok) {
      const apiData = await response.json()
      setItem(apiData['device'])

      // setToastMessage(`Device image has been updated successfully`);
      // setSnackbarOpen(true);
      handleModalClose()
    } else {
      const apiError = response
      console_log("apiError:::", apiError)
      if(apiError.error) {
        setToastMessage(apiError.error);
      }
      setSnackbarOpen(true);
    }

  }
  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={1500}
            message={toastMessage}
          />
          <Typography>Upload device image</Typography>

          <div className='mt-4'>
            <div className='text-center cursor-pointer'>
              <img className={classes.avatar} onClick={() => onClickFileBtn()} src={image === "" ? defaultAvatar : image} alt="" />
            </div>
            <div className='hidden d-none'>
              <input
                ref={fileRef}
                type="file"
                name="upload_file"
                className="file-upload-default upload_file"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => onFileInputChanged(e)}
              />
            </div>
          </div>
          <div className='mt-4'>
            <div className={classes.buttons} style={{ width: '100%', margin: 'auto' }}>
              {/* <Button type="button" color="primary" variant="contained" onClick={() => onClickFileBtn()} disabled={apiLoading}>
                Choose File
              </Button> */}
              <Button type="button" color="primary" variant="contained" onClick={() => onClickSaveBtn()} disabled={apiLoading}>
                Submit
              </Button>
              <Button type="button" color="primary" variant="outlined" onClick={() => setOpen(false)}>
                Close
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DeviceImageUploadModal;
